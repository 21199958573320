import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "submission"
    }}>{`Submission`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchsubmission"
    }}>{`fetchSubmission`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* id -> string = (required) the ID of the submission
`}</code></pre>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submission -> Submission{} = a submission object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchSubmission } from '@kineticdata/react';

fetchSubmission({ id }).then(({ submission }) => console.log(submission));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updatesubmission"
    }}>{`updateSubmission`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* id -> string = (required) the ID of the submission
* values -> { [string]: any } = (required) an object of values to upsert.
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submission -> Submission{} = the updated submission object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateSubmission } from '@kineticdata/react';

const values = {
  'Requested For': 'The New Guy',
  'Requested By': 'The Head Honcho',
};

updateSubmission({ id, values }).then(({ submission }) =>
  console.log(submission),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "submitsubmission"
    }}>{`submitSubmission`}</h2>
    <h3 {...{
      "id": "params-2"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* id -> string = (required) the ID of the submission
* page -> string = (required) the name of the Page being submitted
* values -> { [string]: any } = (required) an object of values to upsert.
* staged -> boolean = indicates whether field validations and page advancement should take place; defaults to false
`}</code></pre>
    <h3 {...{
      "id": "resolves-2"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submission -> Submission{} = the updated submission object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-2"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateSubmission } from '@kineticdata/react';

const values = {
  'Requested For': 'The New Guy',
  'Requested By': 'The Head Honcho',
};

updateSubmission({ id, page: 'Page One', values }).then(({ submission }) =>
  console.log(submission),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "createsubmission"
    }}>{`createSubmission`}</h2>
    <h3 {...{
      "id": "params-3"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* values -> { [string]: any } = (required) an object of values to create.
* formSlug -> string = (required) slug of the form
* kappSlug -> string = (required) slug of the kapp the form is in
* completed -> boolean = should the submission be created with a Submitted coreState; defaults to true
* parent -> string = id of parent submission
`}</code></pre>
    <h3 {...{
      "id": "resolves-3"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submission -> Submission{} = the created submission object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-3"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createSubmission } from '@kineticdata/react';

const values = {
  'Requested For': 'The New Guy',
  'Requested By': 'The Head Honcho',
};

createSubmission({ kappSlug, formSlug, values }).then(({ submission }) =>
  console.log(submission),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "deletesubmission"
    }}>{`deleteSubmission`}</h2>
    <h3 {...{
      "id": "params-4"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* id -> string = (required) the ID of the submission
`}</code></pre>
    <h3 {...{
      "id": "resolves-4"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submission -> Submission{} = the deleted submission object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-4"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { deleteSubmission } from '@kineticdata/react';

deleteSubmission({ id }).then(({ submission }) => console.log(submission));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "savesubmissionmultipart"
    }}>{`saveSubmissionMultipart`}</h2>
    <p><em parentName="p">{`Performs either a create or an update, depending on which parameters are provided. Requires either the `}<inlineCode parentName="em">{`id`}</inlineCode>{` parameter (for an update) or the `}<inlineCode parentName="em">{`formSlug`}</inlineCode>{` and `}<inlineCode parentName="em">{`kappSlug`}</inlineCode>{` parameters (for a create).`}</em></p>
    <h3 {...{
      "id": "params-5"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* id -> string = the ID of a submission
* formSlug -> string = slug of a form
* kappSlug -> string = slug of the kapp the form is in
* values -> { [string]: any } = (required) an object of values to upsert.
* files -> { "field": string, "file": File }[] = (required) an object of values to upsert.
* completed -> boolean = should the submission be created with a Submitted coreState; defaults to true
* parent -> string = id of parent submission
`}</code></pre>
    <h3 {...{
      "id": "resolves-5"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* submission -> Submission{} = the created or updated submission object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-5"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateSubmission } from '@kineticdata/react';

const values = {
  'Requested For': 'The New Guy',
  'Requested By': 'The Head Honcho',
};
const files = [
  {
    field: 'Attachment',
    file: new File(['test'], 'test'),
  },
];

updateSubmission({ id, values, files }).then(({ submission }) =>
  console.log(submission),
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      